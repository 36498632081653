import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Backlash`}</strong>{` occurs when a `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Conviction`}</a>{` is made false by the `}<a parentName="p" {...{
        "href": "/The%20Luminary",
        "title": "The Luminary"
      }}>{`The Luminary`}</a>{`, allowing reality to return to the `}<a parentName="p" {...{
        "href": "/Cornerstone",
        "title": "Cornerstone"
      }}>{`Cornerstone`}</a>{`. In most cases, Backlash is all but undetectable. However, in more significant `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Convictions`}</a>{`, Backlash can manifest as both internal and external harm to the `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronist`}</a>{` attempting to manifest.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      